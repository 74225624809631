import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import CurrencyFormat from '../../components/CurrencyFormat';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';

// import { Container } from './styles';

const ConfirmRecharge: React.FC = () => {

    const navigate = useNavigate();
    const { amount, date, type, borderaux } = useLocation().state as { amount: number, date: string, type: number, borderaux: string };
    const { balance } = useSelector((state: Reducers) => state.account);
    return (
        <>
            <PageTitle>
                Recibo de carregamento da conta
            </PageTitle>
            <Card>

                {type === 1 ?
                    <FormGroup>
                        <label htmlFor="">Borderaux</label>
                        <h3>
                            {borderaux}
                        </h3>
                    </FormGroup> :
                    <></>
                }
                <FormGroup>
                    <label htmlFor="">Valor carregado</label>
                    <h3>
                        <CurrencyFormat value={amount * 1} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Data</label>
                    <h3>{date}</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Crédito atual</label>
                    <h3>
                        <CurrencyFormat value={balance} />
                    </h3>
                </FormGroup>

                <FormGroup>
                    <i className='fa fa-check-circle' style={{
                        margin: "40px auto",
                        color: "green",
                        fontSize: "140px",
                    }}></i>
                    <button className='button button-primary'
                        onClick={() => { navigate("/recharge") }}
                    >Voltar</button>
                </FormGroup>
            </Card>
        </>
    );
}

export default ConfirmRecharge;