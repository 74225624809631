import React from 'react';

import Home from './pages/Home';
import Login from './pages/Login';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import store from './store';
import Main from './components/Main';
import Counter from './pages/counters/Counter';
import Borderaux from './pages/Borderaux';
import Credelec from './pages/credelec/Credelec';
import Invoice from './pages/invoice/Invoice';
import Users from './pages/User';
import NewCounter from './pages/counters/New';
import BCI from './pages/banks/bci';
import ShowInvoice from './pages/invoice/show';
import ConfirmInvoice from './pages/invoice/confirm';
import ConfirmCredelec from './pages/credelec/confirm';
import Recharge from './pages/recharge/Recharge';
import ConfirmRecharge from './pages/recharge/confirm';
import Test from './pages/test';


function App() {
  localStorage.setItem("confirmed", "no");

  return (


    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/bci" element={<BCI />} />
            <Route index element={<Login />} />
            <Route element={<Main />}>
              <Route path="/home" element={<Home />} />
              <Route path="counter" element={<Counter />}></Route>
              <Route path="counter/new" element={<NewCounter />}></Route>

              <Route path="recharge" element={<Recharge />}></Route>
              <Route path="recharge/confirm" element={<ConfirmRecharge />}></Route>

              <Route path="credelec" element={<Credelec />}></Route>
              <Route path="invoice" element={<Invoice />}></Route>
              <Route path="invoice/show" element={<ShowInvoice />}></Route>
              <Route path="invoice/confirm" element={<ConfirmInvoice />}></Route>

              <Route path="credelec" element={<Credelec />}></Route>
              <Route path="credelec/confirm" element={<ConfirmCredelec />}></Route>

              <Route path="users" element={<Users />}></Route>


            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
