import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import FooterCard from '../../components/FooterCard';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';

// import { Container } from './styles';

const Credelec: React.FC = () => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState("");
    const [amount, setAmount] = useState(0)
    const [_type, setType] = useState(1);
    const [error, setError] = useState("");
    const [atualIterval, setAtualIterval] = useState<NodeJS.Timer>();

    const dispatch = useDispatch();
    const balance = useSelector((state: Reducers) => state.account.balance);
    const counters = useSelector((state: Reducers) => state.counters.data);
    const [counterType, setCounterType] = useState(1);
    localStorage.setItem("confirmed", "no");


    const selectType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setType(parseInt(e.target.value))
    }


    const selectCounterType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCounter("");
        setCounterType(parseInt(e.target.value))
    }

    const selectCounter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCounter(e.target.value);
    }

    function checkPayment() {
        atualIterval ?? clearInterval((atualIterval));
        const interval = setInterval(() => {
            console.log("Credelec checkPayment");
            if (localStorage.getItem("confirmed") === "yes") {
                clearInterval(interval);
                navigate("/credelec/confirm", { state: { details: { counter, amount: (amount * 1) } } })

            }
        }, 1000);
        console.log(interval);

        setAtualIterval(atualIterval);
    }

    const recharge = () => {
        if (amount < 1) {
            return
        }

        if (_type === 1) {
            if (counter.length > 1 && amount > 0) {
                window.open(window.location.origin + '/bci',
                    'liveMatches',
                    'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=420,height=400');
            }
            checkPayment();
        } else {
            if (balance < amount) {
                setError("* Crédito insuficiente")
                return;
            } else {
                dispatch({ type: "DEBIT_BALANCE", amount: amount * 1 });
                navigate("/credelec/confirm", { state: { details: { counter, amount: (amount * 1) } } })
            }
        }


    }

    useEffect(() => {

        return () => {
            atualIterval ?? clearInterval(atualIterval)
        }
    }, [])

    return (
        <>
            <PageTitle>
                Comprar Credelec
            </PageTitle>
            <Card>
                <FormGroup >
                    <label htmlFor="">Indique o contador</label>
                    <div className='radio-wrapper'>
                        <div>
                            <input type="radio" id='c1' name="counterType" value="1" onChange={selectCounterType} checked={counterType === 1} />
                            <label htmlFor="c1"> Existente</label>
                        </div>
                        <div>
                            <input type="radio" id='c2' name="counterType" value="2" onChange={selectCounterType} checked={counterType === 2} />
                            <label htmlFor="c2"> Novo</label>
                        </div>
                    </div>

                </FormGroup>
                {counterType === 2 ?
                    <FormGroup>
                        <label htmlFor="">Número do contador</label>
                        <input type="text" placeholder='Número do contador'
                            value={counter}
                            onChange={e => { setCounter(e.target.value) }}
                        />
                    </FormGroup> :

                    <FormGroup>
                        <label htmlFor="">Selecione o contador</label>
                        <select name="" id="" defaultValue={""} onChange={selectCounter}>
                            <option value="" disabled defaultValue={""}>
                                ----  Selecione o contador ----

                            </option>
                            {
                                counters.map(counter => <option key={counter.number} value={counter.number}> {counter.desc}</option>)
                            }

                        </select>
                    </FormGroup>
                }
                <FormGroup>
                    <label htmlFor="">Valor a recarregar</label>
                    <input type="number" placeholder='Valor a recaregar'
                        value={amount}
                        onChange={e => { setAmount(e.target.value as unknown as number) }}
                    />
                </FormGroup>
                <FormGroup >
                    <label htmlFor="">Forma de pagamento</label>
                    <div className='radio-wrapper'>
                        <div>
                            <input type="radio" id='1' name="type" value="1" onChange={selectType} checked={_type === 1} />
                            <label htmlFor="1"> Internet Banking</label>
                        </div>
                        <div>
                            <input type="radio" id='2' name="type" value="2" onChange={selectType} checked={_type === 2} />
                            <label htmlFor="2"> Conta corrente</label>
                        </div>
                    </div>

                </FormGroup>
                {
                    _type === 2 ||
                    <FormGroup>
                        <label htmlFor="">Escolha banco</label>
                        <select name="" id="">
                            <option value="">
                                BCI
                            </option>
                        </select>
                    </FormGroup>

                }
                <FormGroup>
                    <span className='error'>{error}</span>
                </FormGroup>
                <FooterCard>
                    <button className='button button-warning' onClick={recharge}>Recarregar</button>
                </FooterCard>
            </Card>
        </>
    );
}

export default Credelec;