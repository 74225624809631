import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
// import { Container } from './styles';

const NewCounter: React.FC = () => {
    const [desc, setDesc] = useState('');
    const [number, setNumber] = useState('')
    const [address, setAddress] = useState('')
    const dispatch = useDispatch();
    const counters = useSelector((state: Reducers) => state.counters.data)
    const navigate = useNavigate();

    const saveCounter = () => {

        let counter: Counter = { desc, number, address };
        console.log(counters);
        dispatch({ type: "ADD_COUNTER", counter })
        navigate("/counter");
    }
    return (
        <>
            <PageTitle>
                Novo Contador
            </PageTitle>
            <Card>
                <FormGroup>
                    <label htmlFor="">Descrição</label>
                    <input type="text" placeholder='Descrição' value={desc} onChange={e => { setDesc(e.target.value) }} />
                    {/* <span className='error'>* Error rerere rere</span> */}
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Número do contador</label>
                    <input type="text" placeholder='Número do contador' value={number} onChange={e => { setNumber(e.target.value) }} />
                    {/* <span className='error'>* Error rerere rere</span> */}
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Endereço</label>
                    <input type="text" placeholder='Endereço do contador' value={address} onChange={e => { setAddress(e.target.value) }} />
                </FormGroup>
                <FormGroup>
                    <button className='button button-success' onClick={() => { saveCounter() }}>Salvar</button>
                    <button className='button button-primary' onClick={() => { navigate("/counter") }}>Cancelar</button>

                </FormGroup>
            </Card>
        </>
    );
}

export default NewCounter;