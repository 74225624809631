import React from 'react';
import NumberFormat from 'react-number-format';

type Props ={
    value:number
}
const CurrencyFormat: React.FC<Props> = ({value}) => {
  return (
    <NumberFormat value={value} decimalScale={2} fixedDecimalScale={true}  displayType={'text'} thousandSeparator={' '} decimalSeparator=','  suffix={' MT'} />
  )
}

export default CurrencyFormat;