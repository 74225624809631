import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import CurrencyFormat from '../../components/CurrencyFormat';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Invoice } from '../../store/reducers/invoicesReducer';

// import { Container } from './styles';

const ConfirmInvoice: React.FC = () => {
    const { invoice } = useLocation().state as { invoice: Invoice };
    const navigate = useNavigate();
    console.log(invoice);


    return (
        <>
            <PageTitle>
                Recibo do pagamento da factura
            </PageTitle>
            <Card>
                <FormGroup>
                    <h2>Confrmado </h2>
                </FormGroup>

                <FormGroup>
                    <label htmlFor="">Número do contador</label>
                    <h3>{invoice.counter}</h3>
                    {/* <span className='error'>* Error rerere rere</span> */}
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Data</label>
                    <h3>{invoice.date}</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Valor</label>
                    <h3>
                        <CurrencyFormat value={(invoice.amount / 1.17)} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">IVA</label>
                    <h3>
                        <CurrencyFormat value={invoice.amount - (invoice.amount / 1.17)} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Rádio difusão</label>
                    <h3>
                        <CurrencyFormat value={0} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Taxa de lixo</label>
                    <h3>
                        <CurrencyFormat value={0} />
                    </h3>
                </FormGroup>

                <FormGroup>
                    <label htmlFor="">Total a pagar</label>
                    <h3>
                        <CurrencyFormat value={invoice.amount} />
                    </h3>
                </FormGroup>

                <FormGroup>
                    {/* <h3 style={{ margin: "auto" }}>Pagamento feito com successo!</h3> */}
                    <i className='fa fa-check-circle' style={{
                        margin: "40px auto",
                        color: "green",
                        fontSize: "140px",
                    }}></i>
                    <button className='button button-primary'
                        onClick={() => { navigate("/invoice") }}
                    >Voltar</button>
                </FormGroup>

            </Card>
        </>
    );
}

export default ConfirmInvoice;