import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Row from '../../components/Row';
import StatsCard from '../../components/StatsCard';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';

const CounterPage: React.FC = () => {
    const counters = useSelector((state: Reducers) => state.counters.data);
    const dispatch = useDispatch();

    const deleteCounter = (counter: Counter) => {
        dispatch({ type: "REMOVE_COUNTER", counter })
    }

    return (
        <>
            <PageTitle>
                Contadores
            </PageTitle>
            <Row>

                <StatsCard desc="Contadores Registrados" number={counters.length + ""} icon='stopwatch-20' iconColor="#fff" backgroundIconColor='#E3642F'>
                </StatsCard>

            </Row>
            <div className="card table-card">
                <div className="card-header"><h3>Contadores</h3></div>
                <div className="card-body">
                    <div className="card no-style">
                        <Link to="/counter/new">
                            <button className="button button-success">
                                Adicionar novo
                            </button>
                        </Link>
                    </div>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Nome
                                    </th>
                                    <th>
                                        Número
                                    </th>
                                    <th>
                                        Endereço
                                    </th>
                                    <th>
                                        Acção
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {counters.map(counter =>
                                    <tr key={counter.number}>
                                        <td>{counter.desc}</td>
                                        <td>{counter.number}</td>
                                        <td>{counter.address}</td>
                                        <td style={{ gap: "5px" }}>
                                            {/* <button className="button button-primary">Editar</button> */}
                                            <button className="button button-danger" onClick={() => { deleteCounter(counter) }}>Apagar</button>
                                        </td>
                                    </tr>


                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CounterPage;