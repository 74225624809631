import React from 'react';



const FooterCard: React.FC = ({ children }) => {
    return (
        <div className='footer-card'>
            {children}
        </div>)
        ;
}

export default FooterCard;