import { combineReducers } from "redux";
import counters, { Counter, CounterState } from "./countersReducer";
import users, { UserState } from "./usersReducer";
import invoices, { InvoiceState } from "./invoicesReducer";
import account, { AccountState } from "./accountReducer";
export interface Reducers {
  counters: CounterState;
  users: UserState;
  invoices: InvoiceState;
  account: AccountState;
}

const reducers = combineReducers({ counters, users, invoices, account });
export default reducers;
