import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import { Invoice } from '../../store/reducers/invoicesReducer';
import CurrencyFormat from '../../components/CurrencyFormat';
import { Reducers } from '../../store/reducers';
import PageTitle from '../../components/PageTitle';
import FooterCard from '../../components/FooterCard';

const ShowInvoice: React.FC = () => {
    const { invoice } = useLocation().state as { invoice: Invoice };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [_type, setType] = useState(1);
    const [error, setError] = useState("");
    const [confirmed, setConfirmed] = useState(false);
    const balance = useSelector((state: Reducers) => state.account.balance);

    localStorage.setItem("confirmed", "no");
    let interval: NodeJS.Timer;

    const pay = () => {
        if (_type === 1) {
            window.open(window.location.origin + '/bci',
                'liveMatches',
                'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=420,height=400');

            checkPayment();
        } else {
            confirm();
        }

    }


    const confirm = () => {
        dispatch({ type: "PAY_INVOICE", invoice });
        if (_type === 2) {
            if (balance < invoice.amount) {
                setError("* Crédito insuficiente")
                return;
            }
            dispatch({ type: "DEBIT_BALANCE", amount: invoice.amount });
        }

        setConfirmed(true)
        clearInterval(interval)
        navigate("/invoice/confirm", { state: { invoice } })

    }

    function checkPayment() {
        clearInterval(interval);
        interval = setInterval(() => {
            console.log("Invoice checkPayment");

            if (localStorage.getItem("confirmed") === "yes") {
                confirm();
            }
        }, 1000);
    }

    const selectType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setType(parseInt(e.target.value))
    }

    return (
        <>
            <PageTitle>
                Detalhes da factura
            </PageTitle>
            <Card>
                <FormGroup>
                    <label htmlFor="">Número do contador</label>
                    <h3>{invoice.counter}</h3>
                    {/* <span className='error'>* Error rerere rere</span> */}
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Data</label>
                    <h3>{invoice.date}</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Valor</label>
                    <h3>
                        <CurrencyFormat value={(invoice.amount / 1.17)} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">IVA</label>
                    <h3>
                        <CurrencyFormat value={invoice.amount - (invoice.amount / 1.17)} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Rádio difusão</label>
                    <h3>
                        <CurrencyFormat value={0} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Taxa de lixo</label>
                    <h3>
                        <CurrencyFormat value={0} />
                    </h3>
                </FormGroup>

                <FormGroup>
                    <label htmlFor="">Total a pagar</label>
                    <h3>
                        <CurrencyFormat value={invoice.amount} />
                    </h3>
                </FormGroup>

                {invoice.paidOut ?

                    <FormGroup>
                        <h3 style={{ color: "green" }}>Factura paga</h3>
                        <button className='button button-primary' onClick={() => { navigate("/invoice") }}>Voltar</button>
                    </FormGroup> :
                    <>
                        <FormGroup >
                            <label htmlFor="">Forma de pagamento</label>
                            <div className='radio-wrapper'>
                                <div>
                                    <input type="radio" id='1' name="type" value="1" onChange={selectType} checked={_type === 1} />
                                    <label htmlFor="1"> Internet Banking</label>
                                </div>
                                <div>
                                    <input type="radio" id='2' name="type" value="2" onChange={selectType} checked={_type === 2} />
                                    <label htmlFor="2"> Conta corrente</label>
                                </div>
                            </div>

                        </FormGroup>

                        {_type === 2 ||
                            <>


                                <FormGroup>
                                    <label htmlFor="">Escolha banco</label>
                                    <select name="" id="">
                                        <option value="">
                                            BCI
                                        </option>
                                    </select>
                                </FormGroup>
                            </>
                        }
                        <FormGroup>
                            <span className='error'>{error}</span>
                        </FormGroup>
                        <FooterCard>
                            <button className='button button-warning' onClick={pay}>PAGAR FACTURA</button>
                            <button className='button button-primary'
                                onClick={() => { navigate("/invoice") }}
                            >Voltar</button>
                        </FooterCard>
                    </>
                }
            </Card>
        </>
    );
}

export default ShowInvoice;