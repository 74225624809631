import React, { } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import CurrencyFormat from '../../components/CurrencyFormat';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';

// import { Container } from './styles';

const ConfirmCredelec: React.FC = () => {
    const { details } = useLocation().state as { details: { counter: string, amount: number } }
    const navigate = useNavigate();

    function randomValue(): string {
        let x = parseInt((Math.random() * 9999) + '').toString().padStart(4, '0');
        return x;
    }

    function round(num: number) {
        var m = Number((Math.abs(num) * 100).toPrecision(15));
        return Math.round(m) / 100 * Math.sign(num);
    }

    return (
        <>
            <PageTitle>
                Recibo de Credelec
            </PageTitle>
            <Card>

                <FormGroup>
                    <label htmlFor="">Código recarga</label>
                    <h3>{randomValue()} {randomValue()} {randomValue()} {randomValue()} {randomValue()}</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Número do contador</label>
                    <h3>{details.counter}</h3>
                    {/* <span className='error'>* Error rerere rere</span> */}
                </FormGroup>

                <FormGroup>
                    <label htmlFor="">Valor de energia</label>
                    <h3>
                        <CurrencyFormat value={(details.amount / 1.105393)} />
                    </h3>

                </FormGroup>

                <FormGroup>
                    <label htmlFor="">IVA</label>
                    <h3>
                        <CurrencyFormat value={(details.amount - (details.amount / 1.105393))} />
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Dívida</label>
                    <h3>0.00 MT</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Taxa de lixo</label>
                    <h3>65,00 MT</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Taxa de rádio</label>
                    <h3>0.00 MT</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Total pago</label>
                    <h3>
                        <CurrencyFormat value={details.amount}></CurrencyFormat>
                    </h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Unidade de energia</label>
                    <h3>{round((details.amount / 1.105393) / 7.64)} KWh</h3>
                </FormGroup>

                <FormGroup>
                    <label htmlFor="">Referência</label>
                    <h3>{parseInt((Math.random() * 10000000000) + '')}</h3>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Número do recibo</label>
                    <h3>Rec. {parseInt((Math.random() * 1000000) + '')}</h3>
                </FormGroup>
                {/* <FormGroup>
                    <label htmlFor=""> Banco</label>
                    <h3>BCI</h3>
                </FormGroup> */}

                <FormGroup>
                    {/* <h3 style={{ margin: "auto" }}>Pagamento feito com successo!</h3> */}
                    <i className='fa fa-check-circle' style={{
                        margin: "40px auto",
                        color: "green",
                        fontSize: "140px",
                    }}></i>
                    <button className='button button-primary'
                        onClick={() => { navigate("/credelec") }}
                    >Voltar</button>
                </FormGroup>
            </Card>
        </>
    );
}

export default ConfirmCredelec;