import React, { useEffect, useState } from 'react';
import Row from '../../components/Row';
import StatsCard from '../../components/StatsCard';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../store/reducers';
import { Invoice } from '../../store/reducers/invoicesReducer';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import CurrencyFormat from '../../components/CurrencyFormat';
import PageTitle from '../../components/PageTitle';

const InvoicePage: React.FC = () => {
    const navigate = useNavigate();

    const invoices = useSelector((state: Reducers) => state.invoices.data)
    const account = useSelector((state: Reducers) => state.account);

    const dispatch = useDispatch();

    const [pendingInvoices, setPendingInvoices] = useState(0);
    const [notPaidInvoices, setNotPaidInvoices] = useState(0);
    const [totalDebit, setTotalDebit] = useState(0);

    function totalDebitFunc() {
        let total = 0;
        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];
            if (invoice.paidOut === false) {
                total += invoice.amount
            }
        }
        setTotalDebit(total);
    }
    useEffect(() => {
        setNotPaidInvoices(invoices.filter(invoice => invoice.paidOut === false).length);
        totalDebitFunc();
    }, [invoices])

    return (
        <>
            <PageTitle>
                Facturas
            </PageTitle>
            <Row>
                <StatsCard desc="Facturas pendentes" number={notPaidInvoices + ""} icon='file-invoice' iconColor="#fff" backgroundIconColor='#f009'>
                </StatsCard>
                <StatsCard desc="Total em dívida" number={<CurrencyFormat value={totalDebit}></CurrencyFormat>} icon='file-invoice-dollar' iconColor="#fff" backgroundIconColor='#00f9' >
                </StatsCard>

                <StatsCard desc="Crédito" number={<CurrencyFormat value={account.balance}></CurrencyFormat>} icon='money-bill' iconColor="#fff" backgroundIconColor='#0f09'>
                </StatsCard>

            </Row>
            <div className="card table-card">
                <div className="card-header"><h3>Facturas</h3></div>
                <div className="card-body">

                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Número
                                    </th>
                                    <th>
                                        Contador
                                    </th>
                                    <th>
                                        Montante
                                    </th>
                                    <th>
                                        Data
                                    </th>

                                    <th>
                                        Estádo
                                    </th>

                                    <th>
                                        Acção
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map(invoice => {
                                    return (
                                        <tr key={invoice.number}>
                                            <td>{invoice.number}</td>
                                            <td>{invoice.counter}</td>
                                            <td>
                                                <CurrencyFormat value={invoice.amount} />
                                            </td>
                                            <td>{invoice.date}</td>
                                            {invoice.paidOut ?
                                                <td style={{ color: "green" }}>
                                                    Pago
                                                </td> :
                                                <td style={{ color: "red" }}>
                                                    Não pago
                                                </td>
                                            }
                                            <td>
                                                <button className="button button-success"
                                                    onClick={() => { navigate("show", { state: { invoice } }) }}>
                                                    <i className='fa fa-eye'></i>
                                                </button>
                                            </td>

                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>

    );
}

export default InvoicePage;