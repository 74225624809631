import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../components/card'
import FormGroup from '../components/FormGroup'
import logo from '../assets/img/logo.png'

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("")
    const [error, setError] = useState("");
    const dispatch = useDispatch();

    const navigate = useNavigate();


    const login = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (password === "pay24" && username !== "") {
            dispatch({ type: "SET_USER", user: { username: username, password: password, role: "admin" } })
            navigate("/home")
        } else {
            setError("* crendeciais incorretas");
        }


    }


    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", backgroundColor: '#fff' }}>
            <form onSubmit={login}>
                <Card>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={logo} alt="" />

                    </div>
                    <FormGroup>
                        <h2 style={{ textAlign: 'center' }}> Portal do Cliente EDM </h2>
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="">Username</label>
                        <input type="text" placeholder='Username'
                            value={username}
                            onChange={e => { setUsername(e.target.value) }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="">Password</label>
                        <input type="password" placeholder='Password'
                            value={password}
                            onChange={e => { setPassword(e.target.value) }}
                        />
                        <span className='error'>{error}</span>
                    </FormGroup>

                    <FormGroup>
                        <button
                            className='button button-warning'
                            style={{ width: '90%' }}
                            onClick={e => login}
                        >
                            login
                        </button>
                    </FormGroup>
                </Card>
            </form>
        </div>
    )
}
