import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import Col from '../../components/Col';
import FormGroup from '../../components/FormGroup';
import Row from '../../components/Row';
import dateFormat from "dateformat";
import PageTitle from "../../components/PageTitle"

const Recharge: React.FC = () => {
    const navigate = useNavigate();
    const [borderaux, setBorderaux] = useState("");
    const [amount, setAmount] = useState(0)
    const [date, setDate] = useState("");
    const [_type, setType] = useState(1);
    const [atualIterval, setAtualIterval] = useState<NodeJS.Timer>();
    const dispatch = useDispatch();
    const today = dateFormat(new Date(), "dd-mm-yyyy");
    localStorage.setItem("confirmed", "no");

    let interval: NodeJS.Timer;

    const rechargeViaEBank = () => {
        if (amount > 0) {
            window.open(window.location.origin + '/bci',
                'liveMatches',
                'top=200,left=200,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=420,height=400');
        }
        checkPayment();
    }

    const selectType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setType(parseInt(e.target.value))
    }

    const confirm = () => {
        clearInterval(interval);
        dispatch({ type: "CREDIT_BALANCE", amount });
        navigate("/recharge/confirm", { state: { amount, date: today, borderaux, type: _type } });
    }

    function checkPayment() {
        clearInterval(interval);
        interval = setInterval(() => {
            console.log("Recharge checkPayment");

            if (localStorage.getItem("confirmed") === "yes") {
                confirm();
            }
        }, 1000);
    }

    const _continue = () => {
        if (amount < 1) {
            return;
        }

        if (_type === 2) {
            rechargeViaEBank();
        } else {
            confirm();
        }
    }

    return (
        <>
            <PageTitle>
                Pré-Carregamento
            </PageTitle>
            <Card>

                <FormGroup>
                </FormGroup>
                <FormGroup >
                    <label htmlFor="">Escolha a forma de Carregamento</label>
                    <div className='radio-wrapper'>
                        <div>
                            <input type="radio" id='1' name="type" value="1" onChange={selectType} checked={_type === 1} />
                            <label htmlFor="1"> Borderaux</label>
                        </div>
                        <div>
                            <input type="radio" id='2' name="type" value="2" onChange={selectType} checked={_type === 2} />
                            <label htmlFor="2"> Internet Banking</label>
                        </div>
                    </div>

                </FormGroup>
                <FormGroup>
                    <label htmlFor="">Montante</label>
                    <input type="number" placeholder='Valor a recaregar'
                        value={amount}
                        onChange={e => { setAmount(e.target.value as unknown as number) }}
                    />
                </FormGroup>
                {_type === 2 ||
                    <>
                        <FormGroup>
                            <label htmlFor="">Número do Borderaux</label>
                            <input type="text" placeholder='Número do Borderaux'
                                value={borderaux}
                                onChange={e => { setBorderaux(e.target.value) }}
                            />
                            {/* <span className='error'>* Error rerere rere</span> */}
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="">Data</label>
                            <input type="text" placeholder='Data'
                                value={date}
                                onChange={e => { setDate(e.target.value) }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="">Ficheiro</label>
                            <input type="file" placeholder='Data'

                                accept='image/png, image/jpeg, .pdf'
                                onChange={e => { setDate(e.target.value) }}

                            />
                        </FormGroup>

                    </>
                }
                <FormGroup>
                    <label htmlFor="">Escolha banco</label>
                    <select name="" id="">
                        <option value="">
                            BCI
                        </option>
                    </select>
                </FormGroup>
                <FormGroup>
                    <button className='button button-success' onClick={_continue}>Continuar</button>
                </FormGroup>
            </Card>
        </>

    );
}

export default Recharge;