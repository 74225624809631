import React, { useState } from 'react'
import '../assets/css/all.min.css'
import '../assets/css/style.css'
import '../assets/css/components.css'
import logo from '../assets/img/logo.png'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { Reducers } from '../store/reducers'
import { useSelector } from 'react-redux'
import CurrencyFormat from './CurrencyFormat'

export default function Main() {
    const account = useSelector((state: Reducers) => state.account);
    const [menuItems, setMenuItems] = useState<string[]>(["#f4f5fa"]);

    const navigate = useNavigate();

    const selectItem = (menu: number) => {
        let temp = menuItems;
        for (let i = 0; i < temp.length; i++) {
            temp[i] = "#fff";
        }
        temp[menu] = "#f4f5fa";
        setMenuItems(temp);
    }

    return (
        <div>
            <input type="checkbox" id="menu-toggle" hidden />
            <div className="side-bar">
                <header>
                    <img className="logo" src={logo} alt="" />
                    <img className="small-logo" src={logo} alt="" />

                </header>
                <div className="side-bar-menu">
                    <ul>
                        <li>
                            <Link onClick={() => selectItem(0)} className="menu-item" style={{ backgroundColor: menuItems[0] }} to="/home">
                                <i className="fa fa-home"></i>
                                <span> Dashboard </span>
                            </Link>
                        </li>
                        {/* <li >
                            <a className="menu-header" href='' >
                                <span>Menu</span>
                            </a>
                        </li> */}
                        <li >
                            <Link className="menu-item" onClick={() => selectItem(1)} style={{ backgroundColor: menuItems[1] }} to="/invoice">
                                <i className="fa fa-file-invoice"></i>
                                <span> Facturas </span>
                            </Link>
                        </li>
                        <li >
                            <Link className="menu-item" onClick={() => selectItem(2)} style={{ backgroundColor: menuItems[2] }} to="/credelec">
                                <i className="fa fa-credit-card"></i>
                                <span> Credelec </span>
                            </Link>
                        </li>
                        <li >
                            <Link className="menu-item" onClick={() => selectItem(3)} style={{ backgroundColor: menuItems[3] }} to="/recharge">
                                <i className="fa fa-charging-station"></i>
                                <span>Pré-Carregamento</span>
                            </Link>
                        </li>
                        <li >
                            <Link className="menu-item" onClick={() => selectItem(4)} style={{ backgroundColor: menuItems[4] }} to="/counter">
                                <i className="fa fa-stopwatch-20"></i>
                                <span>Contadores</span>
                            </Link>
                        </li>


                    </ul>
                </div>
            </div>

            <div className="nav-bar">
                <label className="menu-toggle fa fa-bars" htmlFor="menu-toggle"></label>
                <div className="notification-wrapper">
                    Bem vindo,  <span style={{ fontWeight: "bold" }}>
                        {account.currentUser.username}
                    </span>
                </div>
                <div className="notification-wrapper">
                    Crédito: <span style={{ fontWeight: "bold" }}>
                        <CurrencyFormat value={account.balance}></CurrencyFormat>
                    </span>
                </div>
                <div className="notification-wrapper">
                    <i className="fa fa-bell"></i>
                </div>

                {/* <div className="search-wrapper">
                    <input type="text" placeholder="Search" />
                    <i className="fa fa-search"></i>
                </div> */}

                <div className="profile-wrapper">
                    <i className="fa fa-user"></i>
                    <ul className="profile-options">
                        <li>
                            <i className="fa fa-user"></i>
                            {account.currentUser.username}
                        </li>
                        <li>
                            <i className="fa fa-user-cog"></i>
                            Perfil
                        </li>
                        <li>
                            <i className="fa fa-info-circle"></i>
                            Info
                        </li>
                        <li onClick={() => { navigate("/") }}>
                            <i className="fa fa-sign-out-alt"></i>
                            sair
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main">

                <Outlet />
            </div>
        </div>
    )
}
